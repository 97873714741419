import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["tab", "content"];
  static classes = ["active", "hide"];

  connect() {
    if (this.data.has("equalise")) {
      this.set_min_height();
    }
  }

  switch(e) {
    const tab = e.target.closest("[data-tab]");

    // catch clicks that miss a tab (eg. on the UL)
    if (!tab) {
      return;
    }

    const tab_id = tab.dataset.tab;

    this.tabTargets.forEach((tab) =>
      this.update_tab(tab, tab.dataset.tab === tab_id)
    );
    this.contentTargets.forEach((content) =>
      this.update_content(content, content.dataset.tab === tab_id)
    );
  }

  set_min_height() {
    let min_height = "";

    if (!this.hasContentTarget) {
      return;
    }

    // determine the minimum height
    this.contentTargets.forEach((content) => {
      let hidden = content.classList.contains(this.hideClass);
      if (hidden) {
        content.classList.remove(this.hideClass);
      }

      let height = content.offsetHeight;
      if (height > min_height) {
        min_height = height;
      }

      if (hidden) {
        content.classList.add(this.hideClass);
      }
    });

    // apply to all tabs
    this.contentTargets.forEach(
      (content) => (content.style.minHeight = min_height + "px")
    );
  }

  update_tab(tab, active) {
    if (active) {
      tab.classList.add(this.activeClass);
      tab.querySelector("a").setAttribute("aria-selected", true);
    } else {
      tab.classList.remove(this.activeClass);
      tab.querySelector("a").removeAttribute("aria-selected");
    }
  }

  update_content(content, active) {
    if (active) {
      content.classList.remove(this.hideClass);
      content.classList.add(this.activeClass);
    } else {
      content.classList.add(this.hideClass);
      content.classList.remove(this.activeClass);
    }
  }
}
