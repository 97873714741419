// A controller to enable or disable the target button, depending on the checkbox status

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'button'];

  buttonTargetConnected(e) {
    e.disabled = true
    e.classList.add("disabled");
  }

  connect() {
    this.checkboxTarget.addEventListener("click", (e) =>
      this.checkboxClicked(e)
    );
  }

  checkboxClicked(e) {
    if(e.target.checked == true) {
      this.buttonTarget.disabled = false
      this.buttonTarget.classList.remove("disabled");
    } else {
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.add("disabled");
    }
  }
}
