// Assessor controller will perform operations on quiz one and two as they progress through them

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static targets = ['scoringAnswer', 'nonScoringAnswer'];

	assess() {
		/* Calculate current quiz score */
		let score = 0, numAnsweredQuestions = 0;
		const $scoring_answers = this.element.querySelectorAll('input[data-assessor-target="scoringAnswer"]');
		const $answered_scoring_answers = this.element.querySelectorAll('input[data-assessor-target="scoringAnswer"]:checked');
		const $non_scoring_answers = this.element.querySelectorAll('input[data-assessor-target="nonScoringAnswer"]');

		// +1 point for yes
		$answered_scoring_answers.forEach((answer) => {
			++numAnsweredQuestions;
			if(answer.value === "true")
				++score;
		});

		/* Update score elements */
		const $quiz_score = this.element.querySelector('p#quiz_score');
		const $new_score = this.element.querySelector('p#new_score');
		const $prev_score = this.element.querySelector('p#prev_score');
		const $delta_score = this.element.querySelector('div#delta_score');
		if($quiz_score) $quiz_score.innerText = score + " pts";
		if($new_score) $new_score.innerText = score + " pts";

		// Calculate previous quiz score delta (for quiz 2)
		if($prev_score) {
			const prevScore = parseInt($prev_score.innerText);
			const deltaScore = score - prevScore;
			const symbol = (deltaScore > 0) ? "+" : ((deltaScore < 0) ? "-" : "");
			if ($delta_score) {
				$delta_score.innerText = symbol + Math.abs(deltaScore) + " pts";
				$delta_score.classList.remove("positive"); $delta_score.classList.remove("negative"); $delta_score.classList.remove("neutral");
				if(symbol === "+") $delta_score.classList.add("positive");
				else if(symbol === "-") $delta_score.classList.add("negative");
				else if(symbol === "") $delta_score.classList.add("neutral");
			}
		}

		/* Set button disabledness */
		// Calculate number of answered questions
		$non_scoring_answers.forEach((additionalAnswer) => {
			if(additionalAnswer.checked)
				++numAnsweredQuestions;
		})

		// Disable button if number of answered questions is below total number of questions
		const submitButton = this.element.querySelector('input[type="submit"]');
		const disabledHint = this.element.querySelector('.button-row small');
		if(numAnsweredQuestions < ($non_scoring_answers.length/2) + ($scoring_answers.length/2)) {
			// Disable button
			submitButton.disabled = true;
			submitButton.classList.add("disabled");
			disabledHint.classList.remove("opacity-0");
		}
		else {
			submitButton.disabled = false;
			submitButton.classList.remove("disabled");
			disabledHint.classList.add("opacity-0");
		}
	}
}
