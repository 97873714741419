import Trix from "trix";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let embedHTML = `
    <div data-behavior="embed_container">
      <div class="link_to_embed link_to_embed--new">
        Would you like to embed media from this site?
        <input class="button" type="button" data-behavior="embed_url" value="Yes, embed it">
      </div>
    </div>
    `;

    let trixDialiog = document.querySelector(".trix-dialog--link") !== null;

    if (trixDialiog) {
      document
        .querySelector(".trix-dialog--link")
        .insertAdjacentHTML("beforeend", embedHTML);
    }

    class EmbedController {
      constructor(element) {
        this.youtubePattern =
          /^https:\/\/([^\.]+\.)?youtube\.com\/watch\?v=(.*)/;
        this.vimeoPattern = /^https:\/\/([^\.]+\.)?vimeo\.com\/(.*)/;

        this.element = element;
        this.editor = element.editor;
        this.toolbar = element.toolbarElement;
        this.hrefElement = this.toolbar.querySelector(
          "[data-trix-input][name='href']"
        );
        this.embedContainerElement = this.toolbar.querySelector(
          "[data-behavior='embed_container']"
        );
        this.embedElement = this.toolbar.querySelector(
          "[data-behavior='embed_url']"
        );

        this.reset();
        this.installEventHandlers();
      }

      installEventHandlers() {
        this.hrefElement.addEventListener("input", this.didInput.bind(this));
        this.hrefElement.addEventListener("focusin", this.didInput.bind(this));
        this.embedElement.addEventListener("click", this.embed.bind(this));
      }

      didInput(event) {
        let value = event.target.value.trim();
        let youtubeMatches = value.match(this.youtubePattern);
        let vimeoMatches = value.match(this.vimeoPattern);

        // When patterns are loaded, we can just fetch the embed code
        if (youtubeMatches != null) {
          this.fetch("youtube", youtubeMatches[2]);
        } else if (vimeoMatches != null) {
          this.fetch("vimeo", vimeoMatches[2]);
        } else {
          // No embed code, just reset the form
          this.reset();
        }
      }

      fetch(service, value) {
        fetch(
          `/action_text_video/${encodeURIComponent(value)}?service=${service}`,
          {
            method: "get",
          }
        )
          .then((res) => res.json())
          .then((json) => this.showEmbed(json))
          .catch((err) => this.reset());
      }

      embed(event) {
        if (this.currentEmbed == null) {
          return;
        }

        let attachment = new Trix.Attachment(this.currentEmbed);
        this.editor.insertAttachment(attachment);
        this.element.focus();
      }

      showEmbed(embed) {
        this.currentEmbed = embed;
        this.embedContainerElement.style.display = "block";
      }

      reset() {
        this.embedContainerElement.style.display = "none";
        this.currentEmbed = null;
      }
    }

    document.addEventListener("trix-initialize", function (event) {
      new EmbedController(event.target);
    });
  }
}
