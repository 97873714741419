import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = { data: Array };

  connect() {

    const data = {
      datasets: [
        {
          label: 'First Quiz',
          data: this.dataValue[0],
          fill: false,
          borderColor: '#00A3E4',
          backgroundColor: '#00A3E4',
          tension: 0.1,
          order: 1,
        },
        {
          label: 'Second Quiz',
          data: this.dataValue[1],
          fill: false,
          borderColor: '#122043',
          backgroundColor: '#122043',
          tension: 0.1,
          order: 2,
        },
      ],
    };
    this.element.chart = new Chart(this.element, {
      type: 'line',
      data: data,
      options: {
        scales: {
          y: {
            ticks: {
              // forces step size to be 1
              stepSize: 1
            }
          },
          x: {
            type: 'timeseries',
            time: {
                unit: 'day',
                round: 'day',
                displayFormats: {
                   day: 'dd/MM'
                }
            }
          }
        },
      },
    });
  }
}
