import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content', 'toggler'];

  toggle(event) {
    this.contentTargets.forEach((t) => t.classList.toggle('hidden'));
    event.currentTarget.classList.toggle('active');
  }

  toggleOn(event) {
    this.contentTargets.forEach((t) => t.classList.remove('hidden'));
    event.currentTarget.classList.add('active');
  }

  toggleOff(event) {
    this.contentTargets.forEach((t) => t.classList.add('hidden'));
    event.currentTarget.classList.remove('active');
  }
}
