import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

export default class extends Controller {
  static values = { yes: Number, no: Number };

  connect() {
    const data = {
      labels: ['Yes', 'No'],
      datasets: [
        {
          label: 'My First Dataset',
          data: [this.yesValue, this.noValue],
          backgroundColor: ['#3078a5', '#f8fbfd'],
          borderColor: '#b6cede',
          hoverBackgroundColor: ['#3481b2', '#ffffff'],
          borderWidth: 1,
          hoverOffset: 0,
          offset: 0,
          hoverBorderWidth: 1
        },
      ],
    };

    this.element.chart = new Chart(this.element, {
      type: 'doughnut',
      options: {
        animation: false,
        // events: [],
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      data: data,
    });
  }
}
