// Similar to the toggle controller but three-way. There are two pieces of content that
// we want to switch between (for quiz 2), instead of one piece that is toggled on and off.

// This controller also handles toggling the Guidance for quiz 1 when the user answers 'no'.

// This controller will also clear the form inputs on the hidden content

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { first: String, second: String };
  static targets = ['first', 'second', 'guidance'];

  switch(e) {
    const value = e.currentTarget.value;
    if (value === this.firstValue) {
      if (this.hasFirstTarget) this.show(this.firstTarget);
      if (this.hasSecondTarget) this.hide(this.secondTarget);
      if (this.hasGuidanceTarget) this.hide(this.guidanceTarget);
    } else if (value === this.secondValue) {
      if(this.hasFirstTarget) this.hide(this.firstTarget);
      if(this.hasSecondTarget) this.show(this.secondTarget);
      if(this.hasGuidanceTarget) this.show(this.guidanceTarget);
    }
  }

  hide(target) {
    target.classList.add('hidden');
    target
      .querySelectorAll('input')
      .forEach((input) => input.setAttribute('disabled', true));
  }

  show(target) {
    target.classList.remove('hidden');
    target
      .querySelectorAll('input')
      .forEach((input) => input.removeAttribute('disabled'));
  }
}
