import c0 from './accordion_controller';
import c1 from './autosize_controller';
import c2 from './checks_controller';
import c3 from './confirmation_controller';
import c4 from './donut_controller';
import c5 from './file_upload_controller';
import c6 from './line_controller';
import c7 from './password_peek_controller';
import c8 from './rich_text_controller';
import c9 from './swal_confirm_controller';
import c10 from './tabs_controller';
import c11 from './toggle_controller';
import c12 from './video_controller';
export const definitions = [
	{identifier: 'accordion', controllerConstructor: c0},
	{identifier: 'autosize', controllerConstructor: c1},
	{identifier: 'checks', controllerConstructor: c2},
	{identifier: 'confirmation', controllerConstructor: c3},
	{identifier: 'donut', controllerConstructor: c4},
	{identifier: 'file-upload', controllerConstructor: c5},
	{identifier: 'line', controllerConstructor: c6},
	{identifier: 'password-peek', controllerConstructor: c7},
	{identifier: 'rich-text', controllerConstructor: c8},
	{identifier: 'swal-confirm', controllerConstructor: c9},
	{identifier: 'tabs', controllerConstructor: c10},
	{identifier: 'toggle', controllerConstructor: c11},
	{identifier: 'video', controllerConstructor: c12},
];
