import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  modal(e) {
    e.preventDefault();
    let content = e.target;

    const message = content.dataset.message;
    const text = content.dataset.text;

    Swal.fire({
      title: message,
      text: text,
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      buttonsStyling: false,
      padding: 0,
      customClass: {
        container: "overlay-background",
        popup: "overlay-content ",
      },
    });
  }
}
