import Swal from "sweetalert2";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    message: String,
  };

  get _message() {
    return this.hasMessageValue ? this.messageValue : "Are you sure?";
  }

  get _eventType() {
    if (this.element.tagName === "FORM") {
      return "submit";
    } else if (this.element.tagName === "A") {
      return "click";
    } else {
      throw new Error("Can't handle confirmation on attached element");
    }
  }

  initialize() {
    this.confirm = this.confirm.bind(this);
  }

  connect() {
    if (this.element.dataset.confirmed) {
      delete this.element.dataset.confirmed;
    }
    this.element.addEventListener(this._eventType, this.confirm);
  }

  disconnect() {
    this.element.removeEventListener(this._eventType, this.confirm);
  }

  confirm(event) {
    console.log("Confirm");
    if (!this.element.dataset.confirmed) {
      event.preventDefault();
      Swal.fire({
        title: this._message,
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        buttonsStyling: true,
        customClass: {
          confirmButton: "button primary",
          cancelButton: "button",
        },
      }).then((result) => {
        if (result.value) {
          if (result.value) {
            // User clicked confirm button
            this.element.dataset.confirmed = "true";
            switch (this._eventType) {
              case "submit":
                this.element.submit();
                break;
              case "click":
                this.element.click();
            }
          }
        }
      });
    }
  }
}
