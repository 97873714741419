import c0 from './assessor_controller';
import c1 from './copy_link_controller';
import c2 from './flipflop_controller';
import c3 from './modal_controller';
import c4 from './tooltip_controller';
export const definitions = [
	{identifier: 'assessor', controllerConstructor: c0},
	{identifier: 'copy-link', controllerConstructor: c1},
	{identifier: 'flipflop', controllerConstructor: c2},
	{identifier: 'modal', controllerConstructor: c3},
	{identifier: 'tooltip', controllerConstructor: c4},
];
