import { Controller } from '@hotwired/stimulus';

// This controller heavily inspired by:
// https://stackoverflow.com/questions/65985735/how-to-open-action-text-trix-attachments-or-links-in-new-window-target-blan

export default class extends Controller {
  connect() {
    this.element.querySelectorAll('a').forEach(function(link) {
      if (link.host !== window.location.host) {
        link.target = "_blank";
      }
    });
  }
}
