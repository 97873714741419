import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static get targets() {
    return ["source"];
  }

  connect() {
    this.closeAllBefore = this.data.get("closeAllBefore") || "false";
    this.classToToggle = this.data.get("classToToggle") || "hidden";
  }

  toggle(event) {
    event.preventDefault();
    if (this.closeAllBefore === "true") {
      this.closeOpenElements();

      for (var item of document.querySelectorAll(".accordion-title")) {
        item.classList.remove("active");
      }
    }
    this.toggleClassElement(event.target.closest("div"));
  }

  closeOpenElements() {
    var toggleClass = this.classToToggle;
    this.sourceTargets.forEach(function (item) {
      item.classList.add(toggleClass);
    });
  }

  toggleClassElement(item) {
    item
      .querySelector('[data-target="accordion.source"]')
      .classList.toggle(this.classToToggle);
    item
      .querySelector('[data-target="accordion.source"]')
      .previousElementSibling.classList.add("active");
  }
}
