// A controller to recursively clear checkboxes and radio buttons

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  // An action that doesn't require input targets (only content targets)
  clear(e) {
    this.contentTargets.forEach((target) => {
      const nodes = target.querySelectorAll('input:checked');
      nodes.forEach((node) => {
        node.checked = false;
      });
    });
  }
}
